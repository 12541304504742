import ExperienceCards from "./ExperienceCards";
import ExperienceHeader from "./ExperienceHeader";
import Footer from "../Footer";
import { useEffect } from "react";

const ExperienceContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ExperienceHeader />
      <ExperienceCards />
      <Footer />
    </div>
  );
};

export default ExperienceContainer;
