import "./InfoText.css";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";
import { useContext } from "react";

const InfoText = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <div className="row"></div>
      <div className="col-md-12">
        <h4 className="h4head">Norðurfjörður</h4>
        <div className="row">
          <div className="col-md-12">
            <div className="w-75 paragr">
              {language === "is" ? (
                <>
                  Norðurfjörður er gamall verslunarstaður sem var viðkomustaður
                  strandferðaskipanna þegar sá samgöngumáti var enn við lýði.
                  Þar er stór smábátahöfn og róið er til fiskveiða yfir
                  sumarmánuðina. Verslunarstaðurinn stendur á lítilli eyri og
                  þar er einnig gistiheimilið okkar staðsett, sem er til húsa í
                  gamla frystihúsinu. Einnig er veitingastaður og verslun á
                  staðnum.
                </>
              ) : (
                <>
                  Norðurfjörður used to be a tradestop on the old merchant ships
                  route. Today it is home to a large marina that serves a number
                  of small fishing vessels over the summertime. Our small
                  lodging house is positioned on the square by the marina, in
                  the old municipal cold-storage. There is also a restaurant and
                  a small grocery store nearby.
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="w-75 paragr">
              {language === "is" ? (
                <>
                  Enn norðar eru eyðibyggðar Hornstranda. Margir ferðalangar
                  leggja leið sína þangað yfir sumarmánuðina og hafa þá gjarnan
                  viðkomu í Norðurfirði áður en lagt er í ferðina, hvort sem
                  farið er fótgangandi eða sjóleiðina. Frá Norðurfirði siglir
                  farþegabátur til áfangastaða á Hornströndum.
                </>
              ) : (
                <>
                  The deserted inlets of Hornstrandir national park await those
                  who travel farther north. A popular destination over the
                  summer months, the rugged terrain attracts both experienced
                  hikers and casual wanderers. It is customary for most
                  travelers to stop by in Norðurfjörður before heading into the
                  wilderness, purchasing supplies, or catching the scheduled
                  ferry to Hornstrandir.
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoText;
