import "./GoogleMaps.css";
import { useContext } from "react";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";

const MapContainer = () => {
  // eslint-disable-next-line
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <div className="">
      {language === "is" && (
        <h6 className="head6">Gistiheimilið Bergistangi, 524 Norðurfjörður</h6>
      )}
      {language === "en" && (
        <h6 className="head6">Bergistangi Guesthouse, 524 Norðurfjörður</h6>
      )}

      <iframe
        title="Google Map"
        width="100%"
        height="350"
        allowFullScreen
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6334.656020981205!2d-21.543397482067585!3d66.0505800053907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d4dd9b132ae8c9%3A0xddf0b2c0b1f77c27!2s66.0505344546259%2C%20-21.541203537782334!5e0!3m2!1sen!2sus!4v1658974346827!5m2!1sen!2sus&amp;z=1"
        aria-hidden="false"
      ></iframe>
    </div>
  );
};

export default MapContainer;
