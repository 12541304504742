import hike1 from "../../data/experience/hike1.jpg";
import swim from "../../data/experience/swim.jpg";
import swimpool from "../../data/experience/swimpool.jpg";
import { LanguageContext } from "../../LanguageToggler/LanguageContextProvider";
import { useContext } from "react";
const ExperienceCards = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className="container" style={{ marginTop: "70px" }}>
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <div className="card rounded-0">
            <img
              className="card-img-top rounded-0"
              src={swimpool}
              alt="Card1"
            />
            <div className="card-body">
              {language === "is" ? (
                <h5 className="card-title">Krossneslaug</h5>
              ) : (
                <h5 className="card-title">Krossnes Swimming Pool</h5>
              )}

              <hr />
              {language === "is" ? (
                <p className="card-text">
                  Krossneslaug er einstök sundlaug aðeins 3 km frá Norðurfirði.
                  Hún er staðsett við fjöruborðið með útsýni yfir Húnaflóann.
                  Laugin er ein sú náttúrulegasta á landinu en hún var tekin í
                  notkun 5. júlí árið 1954.
                </p>
              ) : (
                <p className="card-text">
                  Nestled down in the rockbed by the edge of the sea, this
                  unique swimming pool offers a warm, relaxing soak together
                  with a fantastic view of Húnaflói. The pool, which is only
                  three kilometers from Bergistangi, was built in 1954 but has
                  been partially renovated.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="card rounded-0">
            <img className="card-img-top rounded-0" src={swim} alt="Card2" />
            <div className="card-body">
              {language === "is" ? (
                <h5 className="card-title">Sjóböð</h5>
              ) : (
                <h5 className="card-title">Ocean Swimming</h5>
              )}

              <hr />
              {language === "is" ? (
                <p className="card-text">
                  Í Norðurfirði er frábær aðstaða til að stunda sjóböð. Þar er
                  góð sandfjara með stöðugum botni.
                </p>
              ) : (
                <p className="card-text">
                  The sandy beach at the bottom of Norðurfjörður is a perfect
                  location for those who want to go for a refreshing dip in the
                  shallow water.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="card rounded-0">
            <img className="card-img-top rounded-0" src={hike1} alt="Card3" />
            <div className="card-body">
              {language === "is" ? (
                <h5 className="card-title">Útivist</h5>
              ) : (
                <h5 className="card-title">The Great Outdoors</h5>
              )}

              <hr />
              {language === "is" ? (
                <div>Árneshreppur er paradís útivistarfólks, fullt af spennandi gönguleiðum við allra hæfi.</div>
              ) : (
                <div>
                  Norðurfjörður is a hiker’s paradise, overflowing with exciting
                  trails running through the stunning landscape.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCards;
