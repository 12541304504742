import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import swimming from "../data/kross2.jpeg";
import acc from "../data/bergistangi.jpg";
import "./ImageCards.css";
import { useContext } from "react";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";

const ImageCards = () => {
  
  const { language } = useContext(LanguageContext);

  return (
    <div className="containerImageCards container">
      <Row className="justify-content-center">
        <Col sm={12} md={6} className="col-12">
          <Link to="/gistiadstada">
            <Card bg="dark" text="white" className="image w-100 mt-5">
              <Card.Img className="rounded-0 cardim" src={acc} />
              <div className="overlayTwo"></div>
              <Card.ImgOverlay className="d-flex justify-content-center  align-items-center">
                {language === "is" && (
                  <Card.Title className="cardTitle">Gistiaðstaða</Card.Title>
                )}
                {language === "en" && (
                  <Card.Title className="cardTitle">Accommodation</Card.Title>
                )}
              </Card.ImgOverlay>
            </Card>
          </Link>
        </Col>
        <Col sm={12} md={6} className="col-12">
          <Link to="/upplifun">
            <Card
              bg="dark"
              text="white"
              style={{}}
              className=" w-100 image rounded-0 mt-5"
            >
              <Card.Img
                style={{}}
                className="rounded-0 cardim"
                src={swimming}
              />
              <div className="overlayTwo"></div>
              <Card.ImgOverlay className="d-flex justify-content-center align-items-center">
                {language === "is" && (
                  <Card.Title className="cardTitle">Upplifun</Card.Title>
                )}
                {language === "en" && (
                  <Card.Title className="cardTitle">Experience</Card.Title>
                )}
              </Card.ImgOverlay>
            </Card>
          </Link>
        </Col>

      </Row>
    </div>
  );
};

export default ImageCards;
