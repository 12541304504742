import Contact from "./Contact";
import GoogleMaps from "./GoogleMaps";

const CombinedContainer = () => {
  return (
    <div className="container" style={{marginTop:"70px"}}>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          {" "}
          <GoogleMaps />
        </div>
        <div className="col-md-6 col-sm-12">
        <Contact/>
        </div>
      </div>
    </div>
  );
};

export default CombinedContainer;
