import React, { useContext } from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet from react-helmet-async
import "./HomePage.css"; // import your CSS file
import backgroundImage from "../data/sandur.jpg"; // import your image
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";

function HomePage() {
  const handleLinkClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.getBoundingClientRect().top;
      window.scrollBy({ top: offsetTop, behavior: "smooth" });
    }
  };
  const { language, setLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const handleDropdownItemClick = (sectionId) => {
    handleLinkClick(sectionId);
  };

  return (
    <div className="justify-content-center align-content-center d-flex">
      <Helmet>
    
        <title>{language === "is" ? "Gistiheimilið Bergistangi" : "Bergistangi Guesthouse"}</title>
        <meta name="description" content={language === "is" ? "Velkomin á Bergistanga" : "Welcome to Bergistangi"} />
        <meta name="description" content={language === "is" ? "Gistiheimili" : "Guesthouse"} />
        <meta name="description" content={language === "is" ? "Bergistangi" : "Bergistangi"} />
        <meta name="description" content={language === "is" ? "Gistiheimili Bergistangi" : "Guesthouse Bergistangi"} />
       
      </Helmet>
      <div className="row ">
        <div className="col">
          <div className="HomePage">
            <img src={backgroundImage} alt={language === "is" ? "Bakgrunnur" : "Background"} />
            <div className="overlay"></div>
            <div id="hide" className="headercontent row">
              <div className="fontfamily col-lg-4 col-md-4 col-sm-4">
                <div id="text1">
                  {" "}
                  {language === "is" && <p>Gistiheimilið Bergistangi</p>}
                  {language === "en" && <p>Bergistangi Lodges</p>}
                </div>
              </div>
            </div>

            <div className="row headercontent">
              <div className="col" id="show">
                <div className="fontfamily2">Bergistangi</div>
              </div>
              <div
                className="col justify-content-end align-content-end d-flex"
                style={{ marginRight: "15px", marginTop: "-5px" }}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    style={{ border: "none", backgroundImage: "none" }}
                    variant="link"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      fill="white"
                      className="bi bi-list"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropContainer"
                    style={{ backgroundColor: "#0c151a" }}
                  >
                    <Dropdown.Item
                      style={{ color: "white", fontFamily: "Hind Madurai" }}
                      onClick={() => handleDropdownItemClick("siggi")} // Change "siggi" to the appropriate section ID
                    >
                      {language === "is" && <span>Valmöguleikar</span>}
                      {language === "en" && <span>Options</span>}
                    </Dropdown.Item>

                    <Dropdown.Item
                      style={{ color: "white", fontFamily: "Hind Madurai" }}
                      onClick={() => handleDropdownItemClick("contact")} // Change "contact" to the appropriate section ID
                    >
                      {language === "is" && <span>Hafa samband</span>}
                      {language === "en" && <span>Contact</span>}
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item
                      style={{
                        color: "white",
                        fontFamily: "Hind Madurai",
                        textAlign: "center",
                        height: "40px",
                        letterSpacing: "0.05em",
                      }}
                      href="#action/3.4"
                    >
                      <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/gistiadstada"
                      >
                        <div
                          className="justify-content-center align-content-center d-flex"
                          style={{
                            border: "1px",
                            borderStyle: "solid",
                            width: "200px",
                            margin: "auto",
                          }}
                        >
                          {language === "is" && <span>Skoða Gistingu</span>}
                          {language === "en" && <span>Accommodation</span>}
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      style={{
                        color: "white",
                        textAlign: "center",
                        letterSpacing: "0.05em",
                      }}
                      href="#action/3.4"
                    >
                      <div
                        className="justify-content-center align-content-center d-flex"
                        style={{
                          width: "200px",
                          margin: "auto",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              margin: "5px",
                              cursor: "pointer",
                              textDecoration:
                                language === "is" ? "underline" : "none",
                            }}
                            onClick={() => handleLanguageChange("is")}
                          >
                            ÍSL
                          </div>
                          <span> / </span>
                          <div
                            style={{
                              display: "inline-block",
                              margin: "5px",
                              cursor: "pointer",
                              textDecoration:
                                language === "en" ? "underline" : "none",
                            }}
                            onClick={() => handleLanguageChange("en")}
                          >
                            ENG
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="welcome">
              <div>
                {language === "is" && (
                  <h6 className="nord">NORÐURFJÖRÐUR / ÍSLAND</h6>
                )}
                {language === "en" && (
                  <h6 className="nord">NORÐURFJÖRÐUR / ICELAND</h6>
                )}
              </div>
              <div>
                {language === "is" && (
                  <h1 className="welcomehead">
                    Velkomin á <em>Bergistanga</em>
                  </h1>
                )}
                {language === "en" && (
                  <h1 className="welcomehead">
                    Welcome to <em>Bergistangi</em>
                  </h1>
                )}
              </div>
            </div>
            <div
              onClick={() => handleLinkClick("siggi")}
              className="justify-content-center siggi align-content-center d-flex"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="2em"
                width="2em"
              >
                <path d="M21.886 5.536A1.002 1.002 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13a.998.998 0 001.644 0l9-13a.998.998 0 00.064-1.033zM12 17.243L4.908 7h14.184L12 17.243z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
