import image from "../data/new2.png";
import "./ImageBook.css";
import { useContext } from "react";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";
import { Link } from "react-router-dom";

const ImageBook = () => {
  // eslint-disable-next-line
  const { language } = useContext(LanguageContext);

  return (
    <div className="image-container container">
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center">
          <div className="position-relative">
            <img
              className="img-fluid"
              style={{ objectFit: "contain"}}
              src={image}
              alt="mountain"
            />
            <div className="overlayThree position-absolute"></div>
            <div className="text-container position-absolute">
              {language === "is" && (
                <h1 className="text-white text-center">
                  Við hlökkum til að taka á móti <em>ykkur</em>
                </h1>
              )}
              {language === "en" && (
                <h1 className="text-white text-center">
                  Looking forward to <em>welcome</em> you
                </h1>
              )}
              <Link to="/gistiadstada">
                {language === "is" && (
                  <div className="bookStay">BÓKAÐU ÞÍNA GISTINGU</div>
                )}
                {language === "en" && (
                  <div className="bookStay">BOOK YOUR STAY</div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBook;
