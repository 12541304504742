import "./App.css";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"; // Import Navigate
import HomeContainer from "./components/HomeContainer";
import GuesthouseHeader from "./components/Guesthouse-header";
import { LanguageContext } from "./LanguageToggler/LanguageContextProvider";
import { useState } from "react";
import ExperienceContainer from "./components/ExperienceComponents/ExperienceContainer";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const [language, setLanguage] = useState("is");

  // Check if URL ends with "/index.html" and redirect if necessary


  return (
    <HelmetProvider>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <div className="cover-section">
          <Router>
            <Routes>
              <Route path="/" element={<HomeContainer />} />
              <Route path="/gistiadstada" element={<GuesthouseHeader />} />
              <Route path="/upplifun" element={<ExperienceContainer />} />
            </Routes>
          </Router>
        </div>
      </LanguageContext.Provider>
    </HelmetProvider>
  );
}

export default App;
