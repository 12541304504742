import "./Footer.css";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";
import { useContext } from "react";
const Footer = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className="mt-5 " style={{ backgroundColor: "#240701" }}>
      <div className="container footer-info">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h4 className="mt-4 footerheader">Gistiheimilið Bergistangi</h4>
            <div className="infotext">Bergistangi 1-2</div>
            <div className="infotext">524 Norðurfjörður</div>
            <div className="infotext">+354 8399994</div>
            <div className="infotext">info@bergistangi.is</div>
          </div>
          <div className="col-md-4 col-sm-12">
            {language === "is" ? (
              <h4 className="mt-4 footerheader">Um okkur</h4>
            ) : (
              <h4 className="mt-4 footerheader">About us</h4>
            )}

            {language === "is" ? (
              <div className="infotext">
                Gistiheimilið á Bergistanga er fjölskyldurekið gistiheimili.
                Hjónin Margrét Jónsdóttir og Gunnsteinn Gíslason stofnuðu það
                árið 1997 og ráku það í 25 ár. En 2022 taka börnin þeirra Jón,
                Gíslína, Guðrún, Gísli og Álfheiður við keflinu.
              </div>
            ) : (
              <div className="infotext">
                {" "}
                Bergistangi is a family operated lodging house. Margrét
                Jónsdóttir and her husband, Gunnsteinn Gíslason, started housing
                weary travelers in 1997 and oversaw the facility for 25 years.
                After their retirement in 2022, the couple’s five children, Jón,
                Gíslína, Guðrún, Gísli and Álfheiður took over.
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="pb-3" style={{ textAlign: "center", fontSize: "12px" }}>
          © 2023 Gistiheimilið Bergistangi
        </div>
      </div>
    </div>
  );
};

export default Footer;
