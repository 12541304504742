// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAMuwv1RfYQx3jiZf16Oznb6zVHEJ1ofes",
    authDomain: "bergistangi.firebaseapp.com",
    projectId: "bergistangi",
    storageBucket: "bergistangi.appspot.com",
    messagingSenderId: "949689326086",
    appId: "1:949689326086:web:fa36402e6880aa6b6f0187"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const db = getDatabase(app);




