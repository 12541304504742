import "./Contact.css";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { db } from "../firebase";
import { set, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "", message: "" });

  const { language } = useContext(LanguageContext);

  const date = new Date();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = useCallback(() => {
    const newErrors = { name: "", email: "", message: "" };
    let valid = true;

    if (!name) {
      newErrors.name = language === "is" ? "Nafn er nauðsynlegt" : "Name is required";
      valid = false;
    }
    if (!email) {
      newErrors.email = language === "is" ? "Netfang er nauðsynlegt" : "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = language === "is" ? "Ógilt netfang" : "Invalid email address";
      valid = false;
    }
    if (!message) {
      newErrors.message = language === "is" ? "Skilaboð eru nauðsynleg" : "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }, [name, email, message, language]);

  useEffect(() => {
    if (Object.values(errors).some(error => error)) {
      const newErrors = { ...errors };
      if (!name) {
        newErrors.name = language === "is" ? "Nafn er nauðsynlegt" : "Name is required";
      }
      if (!email) {
        newErrors.email = language === "is" ? "Netfang er nauðsynlegt" : "Email is required";
      } else if (!validateEmail(email)) {
        newErrors.email = language === "is" ? "Ógilt netfang" : "Invalid email address";
      }
      if (!message) {
        newErrors.message = language === "is" ? "Skilaboð eru nauðsynleg" : "Message is required";
      }
      setErrors(newErrors);
    }// eslint-disable-next-line
  }, [language, name, email, message]);

  const writeToDatabase = () => {
    if (!validateForm()) {
      return;
    }

    const id = uuidv4();
    const databaseRef = ref(db, `contacts/${id}`);

    set(databaseRef, {
      name,
      email,
      message,
      date
    });

    setName("");
    setEmail("");
    setMessage("");
    setErrors({ name: "", email: "", message: "" });
  };

  return (
    <div className="contact-container w-100">
      {language === "is" && <h6 className="head6">Hafa samband</h6>}
      {language === "en" && <h6 className="head6">Contact</h6>}

      <div className="mb-2 m-auto">
        {language === "is" && <label className="form-label">Nafn</label>}
        {language === "en" && <label className="form-label">Name</label>}
        <input
          required
          value={name}
          onChange={handleNameChange}
          type="text"
          className="form-control no-border-radius-input"
        />
        {errors.name && (
          <div style={{ color: "#ff6666", fontSize: "0.8rem", marginTop: "0.1rem" }}>
            {errors.name}
          </div>
        )}
      </div>
      <div className="mb-2 m-auto">
        {language === "is" && <label className="form-label">Netfang</label>}
        {language === "en" && <label className="form-label">Email</label>}
        <input
          required
          value={email}
          onChange={handleEmailChange}
          type="email"
          className="form-control no-border-radius-input"
        />
        {errors.email && (
          <div style={{ color: "#ff6666", fontSize: "0.8rem", marginTop: "0.1rem" }}>
            {errors.email}
          </div>
        )}
      </div>
      <div className="mb-2 m-auto">
        {language === "is" && <label className="form-label">Skilaboð</label>}
        {language === "en" && <label className="form-label">Message</label>}
        <textarea
          required
          value={message}
          onChange={handleMessageChange}
          className="form-control no-border-radius-input"
          rows="3"
        ></textarea>
        {errors.message && (
          <div style={{ color: "#ff6666", fontSize: "0.8rem", marginTop: "0.1rem" }}>
            {errors.message}
          </div>
        )}
      </div>
      <button
        className="btn btn-primary btn-no-radius w-100 mt-2"
        onClick={writeToDatabase}
      >
        {language === "is" && <span>Senda skilaboð</span>}
        {language === "en" && <span>Send message</span>}
      </button>
    </div>
  );
};

export default Contact;
