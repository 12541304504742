import { useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Guesthouse-cards.css";
import { LanguageContext } from "../LanguageToggler/LanguageContextProvider";
import { useContext } from "react";
import photo from "../data/room1/photo.jpg";

import photo2 from "../data/room1/photo2.jpg";
import photo3 from "../data/room1/photo3.jpg";
import photo4 from "../data/room1/photo4.jpg";
import photo5 from "../data/room1/photo5.jpg";
import photo6 from "../data/room1/photo6.jpg";
import photo7 from "../data/room1/photo7.jpg";
import photo8 from "../data/room1/photo8.jpg";

import photo9 from "../data/room2/photo9.jpg";
import photo10 from "../data/room2/photo10.jpg";
import photo11 from "../data/room2/photo11.jpg";
import photo12 from "../data/room2/photo12.jpg";
import photo13 from "../data/room2/photo13.jpg";
import photo14 from "../data/room2/photo14.jpg";
import photo15 from "../data/room2/photo15.jpg";
import photo16 from "../data/room2/photo16.jpg";
import photo17 from "../data/room2/photo17.jpg";
import photo18 from "../data/room2/photo18.jpg";
import photo19 from "../data/room2/photo19.jpg";
import photo20 from "../data/room2/photo20.jpg";
import photo21 from "../data/room2/photo21.jpg";
import photo22 from "../data/room2/photo22.jpg";
import photo23 from "../data/room2/photo23.jpg";
import photo24 from "../data/room3/photo24.jpg";
import photo25 from "../data/room3/photo25.jpg";
import photo26 from "../data/room3/photo26.jpg";
import photo27 from "../data/room2/photo27.jpg";
import photo28 from "../data/room2/photo28.jpg";
import photo29 from "../data/room2/photo29.jpg";
import photo30 from "../data/room2/photo30.jpg";
import photo31 from "../data/room2/photo31.jpg";
import photo32 from "../data/room2/photo32.jpg";

const GuesthouseCards = () => {
  const [index, setIndex] = useState(0);
  const images = [
    photo,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo30,
    photo31,
    photo32,
    photo27,
    photo28,
    photo29,
  ];
  const [indexTwo, setIndexTwo] = useState(0);
  const imagesTwo = [
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
  ];
  const [indexThree, setIndexThree] = useState(0);
  const imagesThree = [
    photo19,
    photo20,
    photo18,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
  ];

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleSelectTwo = (selectedIndexTwo, e) => {
    setIndexTwo(selectedIndexTwo);
  };
  const handleSelectThree = (selectedIndexThree, e) => {
    setIndexThree(selectedIndexThree);
  };

  const { language } = useContext(LanguageContext);

  return (
    <div className="container justify-content-center align-content-center d-flex mt-5">
      <div className="row" style={{ maxWidth: "1600px" }}>
        {/* Card 1 */}
        <div className="col-sm-12 col-md-4 justify-content-center align-content-center d-flex">
          <div className="card w-100 rounded-0 m-3">
            <Carousel
              style={{ height: "", objectFit: "contain" }}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {images.map((image) => (
                <Carousel.Item key={image}>
                  <img className="d-block w-100" src={image} alt="images" />
                </Carousel.Item>
              ))}
            </Carousel>
            {/* Card 1 content */}
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: "center" }}>
                Bergistangi 1
              </h5>
              <hr />
              <button
                style={{ color: "" }}
                className="btn btn-primary btn-no-radius w-100"
                onClick={() =>
                  window.open(
                    "https://www.booking.com/hotel/is/gistiheimilid-bergistangi.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaHCIAQGYAQm4ARjIAQzYAQHoAQH4AQuIAgGoAgS4Au-R0bAGwAIB0gIkYzhhY2IxY2YtMTcwYi00YmQwLWFlYjgtZjA4NWE0ZWVlOThl2AIG4AIB&sid=e21952aa76c7685809863ca3f34692d1&all_sr_blocks=836489605_349186104_0_0_0;checkin=2024-06-26;checkout=2024-06-27;dest_id=-2651066;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=836489605_349186104_0_0_0;hpos=1;matching_block_id=836489605_349186104_0_0_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=836489605_349186104_0_0_0__13950;srepoch=1712605434;srpvid=77b48abb25200160;type=total;ucfs=1&#hotelTmpl",
                    "_blank"
                  )
                }
              >
                {language === "is" ? "Bóka gistingu" : "Book your stay"}
              </button>
              <hr/>

              {language === "is" ? (
                <div>
                  Í íbúðarhúsinu á Bergistanga er boðið upp á þrjú rúmgóð
                  herbergi á jarðhæð með sameiginlegri snyrtingu og litlum
                  eldhúskrók. Í hverju herbergi eru tvö rúm en í herbergi með
                  sjávarsýn er lítið borð og stólar. Sameiginlegur inngangur er
                  með íbúð eigenda á annarri hæð en að öðru leyti eru rýmin
                  aðskilin.
                </div>
              ) : (
                <div>
                  The family house at Bergistangi offers three rooms on the
                  ground floor that share a bathroom, a kitchen and a breakfast
                  nook. In every room there are two beds and one of the rooms
                  has a small table and two chairs. The rooms share an entrance
                  with the second floor apartment, where the caretaker lives.
                </div>
              )}

              

            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 justify-content-center align-content-center d-flex">
          <div className="card w-100 rounded-0 m-3">
            <Carousel
              style={{ height: "100%", objectFit: "contain" }}
              activeIndex={indexTwo}
              onSelect={handleSelectTwo}
            >
              {imagesTwo.map((image) => (
                <Carousel.Item key={image}>
                  <img className="d-block w-100" src={image} alt="images" />
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="card-body">
              {" "}
              <h5 className="card-title" style={{ textAlign: "center" }}>
                Bergistangi 2
              </h5>{" "}
              <hr></hr>
              <button
                style={{ color: "" }}
                className="btn btn-primary btn-no-radius w-100"
                onClick={() =>
                  window.open(
                    "https://www.booking.com/hotel/is/gistiheimilid-bergistangi-arneshreppur1.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaHCIAQKYAQm4AQfIAQ3YAQHoAQH4AQuIAgGoAgO4At_zy6EGwAIB0gIkYzk2NTYyMmEtNjMyYi00ZGM2LTk1YTctNzFhMWI0MDE5YWYx2AIG4AIB&sid=8ce1cceb076295bfcf919a46e6bf2ef4&all_sr_blocks=836539501_352152155_6_0_0;checkin=2023-06-05;checkout=2023-06-06;dest_id=-2651066;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=3;highlighted_blocks=836539501_352152155_6_0_0;hpos=3;matching_block_id=836539501_352152155_6_0_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=836539501_352152155_6_0_0__25473;srepoch=1681062437;srpvid=0fc67d113afe0053;type=total;ucfs=1&",
                    "_blank"
                  )
                }
              >
                {language === "is" ? "Bóka gistingu" : "Book your stay"}
              </button>
              <hr />
              {language === "is" ? (
                <div>
                  Boðið er upp á gistingu í frystihúsi, sem var byggt í tengslum
                  við sláturhús á staðnum og var notað sem slíkt í þrjátíu ár.
                  Árið 2009 var ráðist í að breyta því í gistiheimili. Í húsinu
                  eru þrjú herbergi með sameiginlegu salerni og rúmgóðu eldhúsi.
                  Í hverju herbergi er handlaug. Í tveimur minni herbergjunum
                  eru sex svefnpláss í kojum en átta svefnpláss í kojum í
                  stærsta herberginu. Það er gistiaðstaða fyrir samtals tuttugu
                  manns. Húsið hefur verið vinsælt jafnt fyrir hópa sem og
                  einstaklinga.
                </div>
              ) : (
                <div>
                  The old municipal cold-storage has housed the lodge on the
                  square since 2009. It was built adjacent to the local
                  slaughterhouse and was in operation for 30 years. The
                  renovations produced three bedrooms that share a bathroom and
                  a kitchen with a large dining area. There are sanitary sinks
                  in all three rooms, the two smaller ones hold six people and
                  the big one holds eight people. The lodge is suitable for
                  large groups, as well as smaller groups or individuals.
                </div>
              )}
           
      
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 justify-content-center align-content-center d-flex">
          <div className="card w-100 rounded-0 m-3">
            <Carousel
              style={{ height: "", objectFit: "contain" }}
              activeIndex={indexThree}
              onSelect={handleSelectThree}
            >
              {imagesThree.map((image) => (
                <Carousel.Item key={image}>
                  <img className="d-block w-100" src={image} alt="images" />
                </Carousel.Item>
              ))}
            </Carousel>

            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: "center" }}>
                {language === "is" ? "Lúxus Íbuð" : "Luxury Apartment"}
              </h5>
              <hr />
              <button
                style={{ color: "" }}
                className="btn btn-primary btn-no-radius w-100 "
                onClick={() =>
                  window.open(
                    "https://www.booking.com/hotel/is/gistiheimilid-bergistangi-arneshreppur1.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaHCIAQKYAQm4AQfIAQ3YAQHoAQH4AQuIAgGoAgO4At_zy6EGwAIB0gIkYzk2NTYyMmEtNjMyYi00ZGM2LTk1YTctNzFhMWI0MDE5YWYx2AIG4AIB&sid=8ce1cceb076295bfcf919a46e6bf2ef4&all_sr_blocks=836539501_352152155_6_0_0;checkin=2023-06-05;checkout=2023-06-06;dest_id=-2651066;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=3;highlighted_blocks=836539501_352152155_6_0_0;hpos=3;matching_block_id=836539501_352152155_6_0_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=836539501_352152155_6_0_0__25473;srepoch=1681062437;srpvid=0fc67d113afe0053;type=total;ucfs=1&",
                    "_blank"
                  )
                }
              >
                {language === "is" ? "Bóka gistingu" : "Book your stay"}
              </button>
              <hr/> 
              {language === "is" ? (
                <div>
                  Glæsiíbúð í hjarta Norðurfjarðar með svefnaðstöðu fyrir 2-4. Í
                  íbúðinni er herbergi með hjónarúmi. Í stofunni er góður
                  svefnsófi sem tveir geta sofið í. Íbúðin er stílhrein og
                  falleg svo vel ætti að fara um gesti þar.
                </div>
              ) : (
                <div>
                  {" "}
                  In the heart of Norðurfjörður, this luxury apartment holds up
                  to four people. With a single bedroom for two, the living room
                  also has a fold-out couch for two. A modern and elegant
                  accommodation for those who want to enjoy comfort on their
                  travels.{" "}
                </div>
              )}

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuesthouseCards;
