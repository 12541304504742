import CombinedContainer from "./CombinedContainer";
import Footer from "./Footer";
import HomePage from "./HomePage";
import ImageBook from "./ImageBook";
import ImageCards from "./ImageCards";
import InfoText from "./InfoText";

const HomeContainer = () => {
  
  return (
    <div className="" >
      <HomePage />
      <div id="siggi">
        <ImageCards />
      </div>
      <InfoText />
      <ImageBook />
      <div id="contact">
        <CombinedContainer />
      </div>
      <Footer />
    </div>
  );
};

export default HomeContainer;
