import "./ExperienceHeader.css";
import images from "../../data/fjord.jpg";
import { Link } from "react-router-dom";

import { useContext } from "react";
import { LanguageContext } from "../../LanguageToggler/LanguageContextProvider";

const ExperienceHeader = () => {

  const { language } = useContext(LanguageContext);
  
  return (
    <div>
      <div className="row">
        <div className="col justify-content-center align-items-center d-flex">
          <div className="guesthouse position-relative">
            <img src={images} className="img-fluid" alt="background" />
            <div className="overlay5">
              <div>
                {language === "is" && <div className="text">Upplifun</div>}
                {language === "en" && <div className="text">Experience</div>}
              </div>
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                <div>
              
                    <div className="homeButton">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                  
             
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceHeader;
